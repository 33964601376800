import React, { useMemo } from 'react'
import type { HeadFC, PageProps } from 'gatsby'
import { Typography } from '@wicadu/arepa/ui'
import { isBrowser } from '@wicadu/arepa/utils'
import styled from '@emotion/styled'

export const translate: any = {
  es: {
    TITLE: '¡Ups, algo salió mal!',
    DESCRIPTION: '¡Lo sentimos por las molestias! Nuestro equipo está trabajando para solucionarlo lo antes posible',
    CALL_TO_ACTION_1: 'Mientras tanto. Intenta volver a la',
    CALL_TO_ACTION_2: 'página de inicio',
  },
  en: {
    TITLE: 'Oops, something went wrong!',
    DESCRIPTION: 'We\'re sorry for the trouble! Our team is working to fix this as fast as possible',
    CALL_TO_ACTION_1: 'In the meantime. Try going back to',
    CALL_TO_ACTION_2: 'the homepage',
  }
}

const companyName: string = 'wicadu'
const url: string = `${process.env.HELP_WEBAPP_HOST}`

const wicaduCover: string = `${process.env.HELP_WEBAPP_HOST}/logos/logo.png`
const wicaduCoverWidth: number = 600
const wicaduCoverHeight: number = 600

const defaultLang: string = 'en'
const defaultLocale: string = 'en'

export const Head: HeadFC = () => {
  const { lang, locale } = useMemo(() => {
    if (isBrowser()) {
      const navigatorLang: string = isBrowser() ? navigator.language : defaultLang

      const lang: string = navigatorLang.startsWith('es') ? 'es' : defaultLang
      const locale: string = lang === 'es' ? 'es_419' : defaultLocale

      return { lang, locale }
    }

    return { lang: defaultLang, locale: defaultLocale }
  }, [])

  const title: string = useMemo(() => translate[lang].TITLE, [lang])
  const description: string = useMemo(() => translate[lang].DESCRIPTION, [lang])

  return (
    <>
      <html lang={lang} />
      <meta charSet='utf-8' />
      <meta name='robots' content='noindex, nofollow' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      <title>{`${title} | ${companyName}`}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={url} />

      <meta property='og:locale' content={locale} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={wicaduCover} />
      <meta property='og:url' content={url} />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@wicaduapp' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={wicaduCover} />
      <meta name='twitter:image:width' content={wicaduCoverWidth} />
      <meta name='twitter:image:height' content={wicaduCoverHeight} />

      <meta name='twitter:creator' content='@wicaduapp' />

      <script type='application/ld+json'>
        {`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${title}",
          "description": "${description}",
          "image": {
            "@type": "ImageObject",
            "url": "${wicaduCover}",
            "width": "${wicaduCoverWidth}",
            "height": "${wicaduCoverHeight}"
          },
          "url": "${url}",
          "isAccessibleForFree": false,
          "identifier": "500"
        }`}
      </script>
    </>
  )
}

const InternalServerErrorPage: React.FC<PageProps> = () => {
  const lang = useMemo(() => {
    if (isBrowser()) {
      const navigatorLang: string = isBrowser() ? navigator.language : defaultLang
      return navigatorLang.startsWith('es') ? 'es' : defaultLang
    }

    return defaultLang
  }, [])

  return (
    <main style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '20px',
      margin: 20,
    }}>
      <Typography type='title'>{translate[lang].TITLE}</Typography>
      <Typography type='description'>{translate[lang].DESCRIPTION}</Typography>
      <Typography type='description'>
        {translate[lang].CALL_TO_ACTION_1} <Link href={process.env.HELP_WEBAPP_HOST} rel='noopener noreferrer'>{translate[lang].CALL_TO_ACTION_2}</Link>
      </Typography>
    </main>
  )
}

const Link = styled.a``

export default InternalServerErrorPage
